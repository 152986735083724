exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-customization-tsx": () => import("./../../../src/pages/customization.tsx" /* webpackChunkName: "component---src-pages-customization-tsx" */),
  "component---src-pages-dashboard-tsx": () => import("./../../../src/pages/dashboard.tsx" /* webpackChunkName: "component---src-pages-dashboard-tsx" */),
  "component---src-pages-final-tsx": () => import("./../../../src/pages/final.tsx" /* webpackChunkName: "component---src-pages-final-tsx" */),
  "component---src-pages-form-builder-tsx": () => import("./../../../src/pages/formBuilder.tsx" /* webpackChunkName: "component---src-pages-form-builder-tsx" */),
  "component---src-pages-form-tsx": () => import("./../../../src/pages/form.tsx" /* webpackChunkName: "component---src-pages-form-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-login-tsx": () => import("./../../../src/pages/login.tsx" /* webpackChunkName: "component---src-pages-login-tsx" */),
  "component---src-pages-payment-tsx": () => import("./../../../src/pages/payment.tsx" /* webpackChunkName: "component---src-pages-payment-tsx" */),
  "component---src-pages-protected-tsx": () => import("./../../../src/pages/protected.tsx" /* webpackChunkName: "component---src-pages-protected-tsx" */),
  "component---src-pages-registration-form-2-tsx": () => import("./../../../src/pages/registrationForm2.tsx" /* webpackChunkName: "component---src-pages-registration-form-2-tsx" */),
  "component---src-pages-registration-form-3-tsx": () => import("./../../../src/pages/registrationForm3.tsx" /* webpackChunkName: "component---src-pages-registration-form-3-tsx" */),
  "component---src-pages-reset-password-tsx": () => import("./../../../src/pages/reset-password.tsx" /* webpackChunkName: "component---src-pages-reset-password-tsx" */),
  "component---src-pages-signup-tsx": () => import("./../../../src/pages/signup.tsx" /* webpackChunkName: "component---src-pages-signup-tsx" */),
  "component---src-pages-uploading-tsx": () => import("./../../../src/pages/uploading.tsx" /* webpackChunkName: "component---src-pages-uploading-tsx" */)
}

