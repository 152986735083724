// import api from "../api/api";
import { navigate } from "gatsby";
import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  ReactNode,
} from "react";

interface User {
  name: string;
  id: number;
}

interface AuthContextType {
  isAuthenticated: boolean;
  user: User | null;
  accessToken: string | null;
  login: (token: string, user: User, order: {}) => void;
  logout: () => void;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

const loadAuthData = () => {
  const token = localStorage.getItem("accessToken");
  const user = localStorage.getItem("user");
  return {
    accessToken: token ? JSON.parse(token) : null,
    user: user ? JSON.parse(user) : null,
  };
};

export const AuthProvider = ({ children }: { children: ReactNode }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [user, setUser] = useState<User | null>(null);
  const [accessToken, setAccessToken] = useState<string | null>(null);

  useEffect(() => {
    const { accessToken, user } = loadAuthData();
    if (accessToken && user) {
      setIsAuthenticated(true);
      setUser(user);
      setAccessToken(accessToken);
    }
  }, []);

  const login = (token: string, user: User, order: any) => {
    let data = {};
    if (order) {
      data = order;
    }

    console.log("order", order);

    setIsAuthenticated(true);
    setUser(user);
    setAccessToken(token);
    localStorage.setItem("accessToken", JSON.stringify(token));
    localStorage.setItem("user", JSON.stringify(user));
    localStorage.setItem("formData", JSON.stringify(data));
    navigate("/form");
  };

  const logout = () => {
    setIsAuthenticated(false);
    setUser(null);
    setAccessToken(null);
    localStorage.removeItem("accessToken");
    localStorage.removeItem("user");
    navigate("/");
  };

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        user,
        accessToken,
        login,
        logout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};
