import "./src/styles/global.css";
import React from "react";
import { AuthProvider } from "./src/context/auth";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
export const wrapRootElement = ({ element }) => (
  <AuthProvider>
    <ToastContainer />
    {element}
  </AuthProvider>
);
